@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.Snag__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.Snag__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.Snag__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.Snag__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.Snag__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.Snag__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.Snag__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .Snag__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}
.Snag__blog {
    display: flex;
    flex-direction: column;    
}

.Snag__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}
.pricing_page_heading{
    width: 100%;
    margin-bottom: 1rem;

}
.pricing_page_heading h1{
    font-size: 45px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.Snag__blog-heading h1 {
    font-size: 45px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.Snag__blog-container {
    display: flex;
    flex-direction: row;
}

.Snag__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.Snag__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .Snag__blog-container {
        flex-direction: column-reverse;
    }


    .Snag__blog-container_groupA {
        margin: 2rem 0;
    }

    .Snag__blog-container_groupA .Snag__blog-container_article  {
       width: 48%;
    }

    .Snag__blog-container_groupA .Snag__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .Snag__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .Snag__blog-container_groupA .Snag__blog-container_article  {
       width: 100%;
    }

    .Snag__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .Snag__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.Snag__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.Snag__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.Snag__features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.Snag__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.Snag__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.Snag__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .Snag__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .Snag__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .Snag__features-container__feature {
        margin: 1rem 0;
    }
}
.Snag__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.Snag__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.Snag__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.Snag__features-heading p {
    color: var(--color-subtext);
    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.Snag__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .Snag__features {
        flex-direction: column;
    }

    .Snag__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .Snag__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}

.Snag__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-footer);
}

.Snag__footer-heading {
  width: 100%;
  text-align: center;

  margin-bottom: 3rem;
}

.Snag__footer-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 64px;
  line-height: 75px;
}

.Snag__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 1px solid #fff;
  text-align: center;

  margin-bottom: 10rem;
  cursor: pointer;
}

.Snag__footer-btn p {
  font-size: 18px;
  font-family: var(--font-family);
  line-height: 21px;
  color: #fff;
  word-spacing: 2px;
}

.Snag__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.Snag__footer-links div {
  width: 250px;
  margin: 1rem;
}

.Snag__footer-links_logo {
  display: flex;
  flex-direction: column;
}

.Snag__footer-links_logo img {
  width: 125px;
  height: 40px;

  margin-bottom: 1rem;
}

.Snag__footer-links_logo p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 15px;
  color: #fff;
}

.Snag__footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.Snag__footer-links_div h3 {
  font-size: 16px;
  line-height: 17px;
  font-family: var(--font-family);
  color: #fff;

  margin-bottom: 0.9rem;
}

.Snag__footer-links_div p {
  font-size: 14px;
  line-height: 15px;
  font-family: var(--font-family);
  color: #fff;

  margin: 0.5rem 0;
  cursor: pointer;
}

.Snag__footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.Snag__footer-copyright p {
  font-size: 14px;
  font-family: var(--font-family);
  line-height: 15px;
  color: #fff;
}

@media screen and (max-width: 850px) {
  .Snag__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .Snag__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .Snag__footer-links div {
    margin: 1rem 0;
  }

  .Snag__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .Snag__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}

.Snag__header {
  display: flex;
  flex-direction: row;
}

.Snag__header-content {
  /* max-width: 50%; */
  padding: 15px;
  flex: 1 1;
  border-radius: 10px;
  background-color: #040c18;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.Snag__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;

  line-height: 75px;
  letter-spacing: -0.04em;
}

.Snag__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.Snag__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.Snag__header-content__input input {
  flex: 2 1;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: rgb(255, 255, 255);
  border-radius: 10px;
}

.Snag__header-content__input button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #00a99d;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  /* border: 2px solid var(--color-footer);  */

  outline: none;
  cursor: pointer;
  border-radius: 10px;
}

.Snag__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  margin-top: 2rem;
}

.Snag__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.Snag__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.Snag__header-image {
  flex: 1 1;
  display: flex;
  justify-content: top;

  align-items: center;
  font-weight: 800;
  font-size: 62px;
  letter-spacing: -0.04em;
  font-family: var(--font-family);
}

.Snag__header-image h1 {
  /* width: 100%; */
  font-size: 52px;
  /* color: white; */
  color: #00a99d;
  padding: 5px;
  border-radius: 10px;
  background-color: #040c18;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;

  /* height: 60%; */
}
.Snag__header-image h1:hover {
  transform: scale(1.2);
}

.Snag__header-image h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200, 100%, 80%);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.Snag__header-image h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* .Snag__header-image h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200, 100%, 80%);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
} */

.Snag__header-image h1:hover {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* html {
  block-size: 100%;
  inline-size: 100%;
}

body {
  min-block-size: 100%;
  min-inline-size: 100%;
  margin: 0;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  font-family: system-ui, sans-serif;
}

@media (orientation: landscape) {
  body {
    grid-auto-flow: column
  } */

@media screen and (max-width: 1050px) {
  .Snag__header {
    flex-direction: column;
  }

  .Snag__header-content {
    margin: 0 0 3rem;
  }
  .Snag__header-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .Snag__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .Snag__header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .Snag__header-content__people {
    flex-direction: column;
  }

  .Snag__header-content__people p {
    margin: 0;
  }

  .Snag__header-content__input input,
  .Snag__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .Snag__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .Snag__header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .Snag__header-content__input input,
  .Snag__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

.Snag__possibility {
    display: flex;
    flex-direction: row;
}

.Snag__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.Snag__possibility-image img {
    width: 100%;
    height: 100%;
}

.Snag__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.Snag__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.Snag__possibility-content h4:last-child {
    color: #ff8A71;
}

.Snag__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.Snag__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .Snag__possibility {
        flex-direction: column;
    }

    .Snag__possibility-image {
        margin: 1rem 0;
    }

    .Snag__possibility-image img {
        width: unset;
        height: unset;
    }

    .Snag__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .Snag__possibility-image img {
        width: 100%;
        height: 100%;
    }
}


.HomeEval {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.HomeEval-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.HomeEval-feature .Snag__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.HomeEval-feature .Snag__features-container_feature-text {
    max-width: 700px;
}

.HomeEval-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.HomeEval-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.HomeEval-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.HomeEval-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.HomeEval-container .Snag__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.HomeEval-container .Snag__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .HomeEval-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .HomeEval-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .HomeEval-feature .Snag__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .HomeEval-feature .Snag__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .HomeEval-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .HomeEval-container .Snag__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.Snag__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Snag__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.Snag__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.Snag__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.Snag__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.Snag__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.Snag__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.Snag__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .Snag__cta {
        flex-direction: column;
    }

    .Snag__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .Snag__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .Snag__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .Snag__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.Snag__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.Snag__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Snag__navbar-links_logo {
    margin-right: 2rem;
}

.Snag__navbar-links_logo img {
    width: 140.56px;
    height: 110.02px;
}

.Snag__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.Snag__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Snag__navbar-links_container p,
.Snag__navbar-sign p,
.Snag__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.Snag__navbar-sign button,
.Snag__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #00a99d;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.Snag__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.Snag__navbar-menu svg {
    cursor: pointer;
}

.Snag__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(255, 22, 22);
}

.Snag__navbar-menu_container p {
    margin: 1rem 0;
}

.Snag__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .Snag__navbar-links_container {
        display: none;
    }

    .Snag__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .Snag__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .Snag__navbar {
        padding: 2rem;
    }

    .Snag__navbar-sign {
        display: none;
    }

    .Snag__navbar-menu_container {
        top: 20px;
    }

    .Snag__navbar-menu_container-links-sign {
        display: block;
    }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  /* background: #161616; */
  /* background-image: url("./assets/bg-image-2.jpg"); */
  background: linear-gradient(rgba(208, 206, 206, 0.8), rgba(22, 22, 22, 0.8)),
    url(/static/media/bg-image-2.2f29e93e.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
th,
td {
  color: white !important;
}
.carousel-section {
  width: 70%;
  margin: auto;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .carousel-section {
    width: 90%;
    margin: auto;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

.tableHeading{
    font-family: var(--font-family) !important;
    font-weight: 800 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: -0.01em !important;
    color: #fff !important;
}
.tableCol{
    font-family: var(--font-family) !important;
}
table, th, td {
    border-collapse: collapse !important;
    border: 1px solid rgba(224, 224, 224, 1) !important;
  }
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}


.tableHeading{
    font-family: var(--font-family) !important;
    font-weight: 800 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: -0.01em !important;
    color: #fff !important;
}
.tableCol{
    font-family: var(--font-family) !important;
}
table, th, td {
    border-collapse: collapse !important;
    border: 1px solid rgba(224, 224, 224, 1) !important;
  }
.Snag__header {
  display: flex;
  flex-direction: row;
}

.Snag__header-content {
  /* max-width: 50%; */
  padding: 15px;
  flex: 1;
  border-radius: 10px;
  background-color: #040c18;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.Snag__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;

  line-height: 75px;
  letter-spacing: -0.04em;
}

.Snag__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
}

.Snag__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.Snag__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: rgb(255, 255, 255);
  border-radius: 10px;
}

.Snag__header-content__input button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #00a99d;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  /* border: 2px solid var(--color-footer);  */

  outline: none;
  cursor: pointer;
  border-radius: 10px;
}

.Snag__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  margin-top: 2rem;
}

.Snag__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.Snag__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.Snag__header-image {
  flex: 1;
  display: flex;
  justify-content: top;

  align-items: center;
  font-weight: 800;
  font-size: 62px;
  letter-spacing: -0.04em;
  font-family: var(--font-family);
}

.Snag__header-image h1 {
  /* width: 100%; */
  font-size: 52px;
  /* color: white; */
  color: #00a99d;
  padding: 5px;
  border-radius: 10px;
  background-color: #040c18;
  text-align: center;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;

  /* height: 60%; */
}
.Snag__header-image h1:hover {
  transform: scale(1.2);
}

.Snag__header-image h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200, 100%, 80%);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.Snag__header-image h1:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
/* .Snag__header-image h1::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: hsl(200, 100%, 80%);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
} */

.Snag__header-image h1:hover {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* html {
  block-size: 100%;
  inline-size: 100%;
}

body {
  min-block-size: 100%;
  min-inline-size: 100%;
  margin: 0;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  font-family: system-ui, sans-serif;
}

@media (orientation: landscape) {
  body {
    grid-auto-flow: column
  } */

@media screen and (max-width: 1050px) {
  .Snag__header {
    flex-direction: column;
  }

  .Snag__header-content {
    margin: 0 0 3rem;
  }
  .Snag__header-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .Snag__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .Snag__header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .Snag__header-content__people {
    flex-direction: column;
  }

  .Snag__header-content__people p {
    margin: 0;
  }

  .Snag__header-content__input input,
  .Snag__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .Snag__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .Snag__header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .Snag__header-content__input input,
  .Snag__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
